// main.js
var Flickity = require('flickity');
// var Masonry  = require('masonry-layout');
// var Macy     = require('macy');
var Isotope  = require('isotope-layout');
var imagesLoaded = require('imagesloaded');

// Isotope libraries
var masonryHorizontal = require('isotope-masonry-horizontal');
var cellsByRow        = require('isotope-cells-by-row');
var packery           = require('isotope-packery');
const { comment }     = require('postcss');

var homeProductsSlider = document.querySelector('.HomeShopProductsSlider');
var homeShopProducts = document.querySelector('.HomeShopProducts .container');


function adjustProductsSlider(left) {
    homeProductsSlider.style.marginLeft = left + 'px';
}

document.addEventListener("DOMContentLoaded", function(event) { 
    var isMobile = window.matchMedia('(max-width: 1024px)').matches;

    window.matchMedia('(max-width: 1024px)').addEventListener('change', function (e) {
        isMobile = e.matches;

        if(homeShopProducts && homeProductsSlider) {
            if(isMobile) {
                adjustProductsSlider(0);
            } else if(!isMobile && homeShopProducts) {
                adjustProductsSlider(homeShopProducts.getBoundingClientRect().left);
            }
        }
    });

    var homeHeaderSlideshow = document.querySelector('.HomeHeaderSlideshow');

    if(homeHeaderSlideshow) {

        var headerSlider = new Flickity( homeHeaderSlideshow, {
            // options...
            contain: true,
            // disable previous & next buttons and dots
            prevNextButtons: false,
            pageDots: false,
            cellAlign: 'center',
            adaptiveHeight: false,
            draggable: true
        });

        var prev = document.querySelector('.HomeHeaderSlideshow-nav--previous');
        var next = document.querySelector('.HomeHeaderSlideshow-nav--next');

        prev.addEventListener('click', function() {

            headerSlider.previous( false, false );
        });

        next.addEventListener('click', function() {

            headerSlider.next( false, false );
        });
    }

    if(homeProductsSlider) {
        

        if(!isMobile) {
            adjustProductsSlider(homeShopProducts.getBoundingClientRect().left);
        }

        var productSlider = new Flickity( homeProductsSlider, {
            // options...
            contain: true,
            prevNextButtons: false,
            pageDots: false,
            cellAlign: 'left',
            draggable: true
        });


        var prod_prev = document.querySelector('.HomeShopProductsSlider-nav--previous');
        var prod_next = document.querySelector('.HomeShopProductsSlider-nav--next');

        prod_prev.addEventListener('click', function() {
            productSlider.previous( false, false );
        });

        prod_next.addEventListener('click', function() {

            productSlider.next( false, false );
        });
    }

    var instagramGrid   = document.querySelector('.HomeInstagramGrid');
    var gridParent = document.querySelector('.HomeInstagramGridContainer');
    var instagramIsotope;


    if(instagramGrid && !isMobile) {
        imagesLoaded(instagramGrid, function () {
            new Promise(resolve => {
                instagramIsotope = new Isotope( instagramGrid, {
                    layoutMode: 'masonryHorizontal',
                    gutter: 10,
                    masonryHorizontal: {
                        rowHeight: 100
                    },
                    resizesContainer: false
                })

                resolve(instagramIsotope);

            }).then(isotope => {
                console.log(isMobile);
                if(!isMobile) {
                    gridParent.scrollLeft = (isotope.element.scrollWidth / 2) - (isotope.element.scrollWidth / 4);
                }
            });
        })
    } else if(isMobile && instagramGrid) {
        imagesLoaded(instagramGrid, function () {
            var headerSlider = new Flickity( instagramGrid, {
                // options...
                contain: true,
                // disable previous & next buttons and dots
                prevNextButtons: false,
                pageDots: false,
                cellAlign: 'center',
                adaptiveHeight: true,
                draggable: true
            });


            var insta_prev = document.querySelector('.HomeInstagramGrid-nav--previous');
            var insta_next = document.querySelector('.HomeInstagramGrid-nav--next');

            insta_prev.addEventListener('click', function() {

                headerSlider.previous( false, false );
            });

            insta_next.addEventListener('click', function() {

                headerSlider.next( false, false );
            });
        });
    }



    var comments = document.querySelector('.HomeComments');

    if(comments) {
        var commentWidth = comments.querySelectorAll('.HomeComments-item')[0].scrollWidth;
        // console.log(comments.scrollWidth);
        if(!isMobile) {
            comments.scrollLeft = + commentWidth + (commentWidth / 2);
        } else {
    
            var commentSlider = new Flickity( comments, {
                // options...
                contain: true,
                // disable previous & next buttons and dots
                prevNextButtons: false,
                pageDots: false,
                cellAlign: 'center',
                adaptiveHeight: false,
                draggable: true,
                wrapAround: true
            });
    
        }
    }
});